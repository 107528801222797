"use client";

import { teaserEmbedFinance, teaserOneAccount } from "public/assets";
import { Image } from "@chakra-ui/react";
import s from "./carousel.module.scss"
import classNames from "classnames";
import { useState } from "react";

type Props = {
    defaultIndex?: number
}
export default function Carousel(props: Props) {
    const defaultIndex = props.defaultIndex || 0

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex)
    const showOneAccount = () => {setSelectedIndex(0)}
    const showEmbedFinance = () => {setSelectedIndex(1)}

    return (
        <div className={classNames(s.teaser, s.exploreSolutions)}>
            <div className={s.content}>
                <div className={s.header}>
                    <h2>Explore Finxact-powered solutions</h2>
                    <div className={s.segmentControl}>
                        <input className={classNames(selectedIndex===0 ? s.selected : '')} type="button" value="One Account" onClick={showOneAccount} />
                        <input className={classNames(selectedIndex === 1 ? s.selected : '')} type="button" value="EmbedFinance+" onClick={showEmbedFinance} />
                    </div>
                </div>
                <div className={classNames(s.exploreSolutionsColumns, selectedIndex !== 0 ? s.hide : '')}>
                    <div>
                        <h3>One Account</h3>
                        <h5>Save, spend, borrow, and earn rewards effortlessly</h5>
                        <p className={s.orangeText}>CHECKING | SAVINGS | REVOLVING LOC | REWARDS</p>

                        <p>With &quot;One Account,&quot; provide a unified solution that combines Checking, Savings, Borrowing, and Rewards. This all-in-one account simplifies personal finance management, offering an efficient and rewarding banking experience, driving loyalty and a seamless experience for your institution&apos;s consumers. </p>

                        <b>Streamlined Finance, Amplified Loyalty</b>
                        <p>Offer your customers the ultimate convenience with an all-encompassing account driving engagement and loyalty.</p>

                        <b>Unlock Competitive Advantage</b>
                        <p>Differentiate your portfolio that enhances customer experience and satisfaction, positioning your brand as a leader in financial innovation.</p>

                        <b>Transform Customer Interactions</b>
                        <p>Offer personalized rewards, fostering a deeper connection with your customers and encouraging positive financial behaviors.</p>

                        <a href="/solutions/one-account"><input className="app-btn-reg-secondary-transparent" type="button" value="Explore scenario &gt;" /></a>
                    </div>
                    <div>
                        <Image src={teaserOneAccount.src} alt="" />
                    </div>
                </div>
                <div className={classNames(s.exploreSolutionsColumns, selectedIndex !== 1 ? s.hide : '')}>
                    <div>
                        <h3>Embed Finance+</h3>
                        <h5>Embedded solutions tailor-made for your customers</h5>
                        <p className={s.orangeText}>CHECKING | SAVINGS | REVOLVING LOC | REWARDS</p>

                        <p>Offer &quot;EmbedFinance+&quot; to seamlessly integrate a tailored suite of financial products into your customers&apos; ecosystems, enhancing retail and commercial propositions. This solution boosts loyalty and sales with customized financial services.</p>

                        <b>Customized Financial Engagement</b>
                        <p>Delivers  tailored financial solutions that resonate with individual customer needs, driving both satisfaction and conversion rates.</p>

                        <b>Autonomous Financial Enhancement</b>
                        <p>Empowers customers with tools for  financial growth, making decisions both intuitive and effortless, encouraging loyalty.</p>

                        <b>Dynamic Rewards System</b>
                        <p>Versatile rewards and recognition framework that adapts to customer interactions, consistently enhancing engagement and retention.</p>

                        <a href="/solutions/embed-finance"><input className="app-btn-reg-secondary-transparent" type="button" value="Explore scenario &gt;" /></a>
                    </div>
                    <div>
                        <Image src={teaserEmbedFinance.src} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}